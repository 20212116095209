import { IBranchTransferRecord } from "src/components/BranchTransfer/BranchTransfer.model";
import { IAddAdmission } from "src/pages/AddAdmission/addAdmission.model";
import { ICourseModificationPayload } from "src/pages/CourseModificationModel/CourseModification.model";
import { config } from "src/utils";
import request from "../../lib/axios/request";
import { IApiResponse } from "../../utils/models/common";
import {
  AdmissionCourseCompleted,
  AdmissionsData,
  IAdmission,
  IAdmissionBasicRecord,
  IAdmissionCancelRecord,
  IAdmissionStatus,
  IAssignCourseInfo,
  ICreateAdmissionResponse,
  IDocumentsData,
  IEducationAndProfessionDetailsData,
  IInstallmentModify,
  IMissingAdmission,
  IMissingAdmissionData,
  IParentsDetailsData,
  IPostalCommunicationData,
  IUpdateAdmissionStatus,
  IConcessionApply,
  ICRMLeadEnquiriesData,
  IPushCRMLeadEnquirie,
  ICounsellor,
  IConcessionDetails,
  IAutoFeedbackData,
  IPenaltyData,
  IGetCRMLead,
  ICRMAutoUpdateLead,
  IAppStundetFeedBackData,
} from "./admission.model";
import axios from "axios";
import { IVerifiyCRMRecord } from "src/pages/MissingCRM/MissingCRM.model";
import { IAddPenaltyFormValues } from "src/pages/PenaltyReceipt/AddPenalty/AddPenalty.model";
import { IPenaltyFilter } from "src/pages/PenaltyReceipt/PenaltyReceipt.model";

class AdmissionService {
  ENDPOINT = config.baseApiAdmission + "/admissions";
  CRMGETCOUNSELLORENDPOINT = config.baseApiCRMGetCounsellor;
  CRMGETLEADPOINT = config.baseApiCRMGet;
  CRMAUTOUPDATELEADPOINT = config.baseApiCRMAutoUpdate;

  public getCRMLeadEnquiries = async (
    data: IAdmission | URLSearchParams | {}
  ): Promise<IApiResponse<ICRMLeadEnquiriesData[]>> => {
    const url = `${this.ENDPOINT}/crm-lead-enquiries`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public pushCRMLeadEnquirie = async (
    data: IPushCRMLeadEnquirie
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}/push-crm-lead-enquirie`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public getCRMCounsellor = async (
    data: ICounsellor | URLSearchParams | {}
  ): Promise<IApiResponse<ICounsellor[]>> => {
    const url = `${this.CRMGETCOUNSELLORENDPOINT}`;
    return axios
      .request({
        url,
        method: "GET",
        params: data,
      })
      .then((res) => {
        return res.data;
      });
  };

  public searchAdmissionData = async (
    data: IAdmission | URLSearchParams | {}
  ): Promise<IApiResponse<IAdmission[]>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public searchAdmissionCsvEmailSendData = async (
    data: IAdmission | URLSearchParams | {}
  ): Promise<IApiResponse<IAdmission[]>> => {
    const url = `${this.ENDPOINT}/admission-report`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public searchMissingAdmissionData = async (
    data: IMissingAdmission | URLSearchParams | {}
  ): Promise<IApiResponse<IMissingAdmissionData[]>> => {
    const url = `${this.ENDPOINT}/missing-admission`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public createAdmission = async (
    data: IAddAdmission
  ): Promise<IApiResponse<ICreateAdmissionResponse>> => {
    const url = `${this.ENDPOINT}/create`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public getAdmissionStatusData = async (
    data: IAdmissionStatus | URLSearchParams | {}
  ): Promise<IApiResponse<IAdmissionStatus>> => {
    const url = `${this.ENDPOINT}/status-count`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public getAdmissionRemarksData = async (
    data: IAdmissionStatus | URLSearchParams | {}
  ): Promise<IApiResponse<IAdmissionStatus>> => {
    const url = `${this.ENDPOINT}/remarks`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public getGrIdData = async (
    data: number
  ): Promise<IApiResponse<IAdmissionStatus>> => {
    const url = `${this.ENDPOINT}/${data}/student_details`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public createNewRemark = async (
    data: IAdmissionStatus | URLSearchParams | {}
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/create-remarks`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public createBulkRemark = async (
    data: IAdmissionStatus | URLSearchParams | {}
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/bulk-remark-create`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public cancelAdmission = async (
    data: IAdmissionCancelRecord
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}/status`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public updateAdmissionStatus = async (
    data: IUpdateAdmissionStatus
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}/status`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public editAdmissionBasicDataById = async (
    newdata: IAdmissionBasicRecord
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${newdata.id}`;
    const data = newdata.data;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public courseInfo = async (id: number): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${id}/course-info`;
    return request({ url, method: "GET", params: id }).then((res) => {
      return res.data;
    });
  };

  public assignBatch = async (
    data: IAssignCourseInfo | undefined
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/assign-batch/${data?.admissionSubCourseId}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public unAssignBatch = async (
    id: number | undefined
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/un-assign-batch/${id}`;
    return request({
      url,
      method: "PUT",
      params: id,
    }).then((res) => {
      return res.data;
    });
  };

  public admissionBranchTransfer = async (
    data: IBranchTransferRecord
  ): Promise<IApiResponse<string>> => {
    const url = `${config.baseApiAdmission}/transfer`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public courseModificationById = async (
    data: ICourseModificationPayload
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.admission_id}/course-modification`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public getAdmissionDetailsById = async (
    id: number
  ): Promise<IApiResponse<AdmissionsData>> => {
    const url = `${this.ENDPOINT}/${id}`;
    return request({ url, method: "GET", params: id }).then((res) => {
      return res.data;
    });
  };

  public courseCompleted = async (
    data: AdmissionCourseCompleted
  ): Promise<IApiResponse<AdmissionCourseCompleted>> => {
    const url = `${this.ENDPOINT}/admission-course-completed/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public updatePostalCommunication = async (
    data: IPostalCommunicationData
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}/postal-communication`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public updateParentsDetails = async (
    data: IParentsDetailsData
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}/parent-details`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public updateEducationAndProfessionDetails = async (
    data: IEducationAndProfessionDetailsData
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}/education-profession`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public updateDocuments = async (
    data: IDocumentsData
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.id}/upload-documents`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public deleteAdmissionOtherDocumentById = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}/admission-other-documents`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public installmentModify = async (
    data: IInstallmentModify
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/admission-installment-modify-update`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public searchUnAssignBatchAdmission = async (
    data: IAdmission | URLSearchParams | {}
  ): Promise<IApiResponse<IAdmission[]>> => {
    const url = `${this.ENDPOINT}/un-assign-batch-admission`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public missingCRMDetails = async (
    data: IAdmission | URLSearchParams | {}
  ): Promise<IApiResponse<IAdmission[]>> => {
    const url = `${this.ENDPOINT}/missing-crm-details`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public concessionApplyUpdate = async (
    data: IConcessionDetails
  ): Promise<IApiResponse<IConcessionApply>> => {
    const url = `${this.ENDPOINT}/concession-apply/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public verifiyCRMRecord = async (data: {
    admission_id: number;
  }): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/reverifiy-crm-record`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public crmLeadHistoryRecord = async (
    admission_id: number
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/crm-history/${admission_id}`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };

  public deleteCRMLeadEnquirie = async (id: number) => {
    const url = `${this.ENDPOINT}/${id}/remove-crm-lead-enquirie`;
    return request({ url, method: "DELETE" }).then((res) => {
      return res.data;
    });
  };

  public concessionRemove = async (
    data: IConcessionDetails
  ): Promise<IApiResponse<IConcessionApply>> => {
    const url = `${this.ENDPOINT}/concession-remove/${data.id}`;
    return request({ url, method: "PUT", data }).then((res) => {
      return res.data;
    });
  };

  public createPenalty = async (
    data: IAddPenaltyFormValues | {}
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/create-penalty`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public searchPenaltyData = async (
    data: IPenaltyFilter | URLSearchParams | {}
  ): Promise<IApiResponse<IPenaltyData[]>> => {
    const url = `${this.ENDPOINT}/penalty-details`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
  public autoFeedback = async (
    data: IAutoFeedbackData | {}
  ): Promise<IApiResponse<IAdmission[]>> => {
    const url = `${this.ENDPOINT}/auto-feedback`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public getCRMLead = async (
    data: string | URLSearchParams | {}
  ): Promise<IApiResponse<IGetCRMLead>> => {
    const url = `${this.CRMGETLEADPOINT}`;

    try {
      const response = await axios.request({
        url,
        method: "GET",
        params: {
          fieldName: "phone",
          fieldValue: data,
        },
        headers: {
          Authorization: `${process.env.REACT_APP_CRM_BEARER_TOKEN}`,
          "Content-Type": "application/json",
        },
      });

      const leads = response.data.leads;

      if (leads.length > 0 && leads[0].fields) {
        return leads[0].fields;
      } else {
        throw new Error("No leads found");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error;
      } else {
        throw new Error("Unexpected error occurred");
      }
    }
  };

  public async autoUpdateCRMLead(
    data: ICRMAutoUpdateLead
  ): Promise<IApiResponse<ICRMAutoUpdateLead>> {
    const url = `${this.CRMAUTOUPDATELEADPOINT}`;

    try {
      const response = await axios.request({
        url,
        method: "POST",
        headers: {
          Authorization: `${process.env.REACT_APP_CRM_BEARER_TOKEN}`,
          "Content-Type": "application/json",
        },
        data,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public getAppStundetFeedBackById = async (
    data: IAppStundetFeedBackData
  ): Promise<IApiResponse<IAppStundetFeedBackData>> => {
    const url = `${this.ENDPOINT}/get-app-stundet-feedback`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
}

export default new AdmissionService();
