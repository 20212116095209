import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { IReportState } from "./report.model";
import {
  getCompanyVisitReportData,
  getDegradeAndCancelRemarkData,
  getExpertSessionsReportData,
  getStudentsProjectAnalysisReportData,
  getStudentsTopicAnalysisReportData,
  getLabWiseStudentData,
  getLabWiseBranchUtilizationData,
  getBranchWiseAllLabReport,
  branchWiseHoldStudentReport,
  searchCDGradeStudentHistory,
  downloadCsvCDGradeStudentHistory,
  searchCounsellorData,
  searchStudentExamMarks,
  sendDegradeCancellationCsvFile,
} from "./report.action";
export const initialState: IReportState = {
  companyVisitReportData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  degradeAndCancelRemarkData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  degradeAndCancelRemarkCvData: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  expertSessionsReportData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  topicAnalysisReportData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  labWiseStudentReportData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
    },
  },
  projectAnalysisReportData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  branchUtilizationData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
    },
  },
  getBranchWiseAllLabReport: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  getBranchWiseHoldStudentReport: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  cdGradeHistoryData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  cdGradeCsvHistoryData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  counsellorDetailsData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },

  studentExamMarks: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
};
export const ReportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.companyVisitReportData.message = "";
      state.degradeAndCancelRemarkData.message = "";
      state.degradeAndCancelRemarkCvData.message = "";
      state.cdGradeCsvHistoryData.message = "";
      state.counsellorDetailsData.message = "";
      state.expertSessionsReportData.message = "";
    },
  },
  extraReducers: {
    // Company Visit Report
    [getCompanyVisitReportData.pending.type]: (state) => {
      state.companyVisitReportData.loading = true;
    },
    [getCompanyVisitReportData.fulfilled.type]: (state, action) => {
      state.companyVisitReportData.data = action.payload.data;
      state.companyVisitReportData.message = action.payload.message;
      state.companyVisitReportData.loading = false;
      state.companyVisitReportData.hasErrors = false;
    },
    [getCompanyVisitReportData.rejected.type]: (state, action) => {
      state.companyVisitReportData.loading = false;
      state.companyVisitReportData.hasErrors = true;
      state.companyVisitReportData.message = action.error.message;
    },
    // Degrade & Cancellation Remarks
    [getDegradeAndCancelRemarkData.pending.type]: (state) => {
      state.degradeAndCancelRemarkData.loading = true;
    },
    [getDegradeAndCancelRemarkData.fulfilled.type]: (state, action) => {
      state.degradeAndCancelRemarkData.data = action.payload.data;
      state.degradeAndCancelRemarkData.message = action.payload.message;
      state.degradeAndCancelRemarkData.loading = false;
      state.degradeAndCancelRemarkData.hasErrors = false;
    },
    [getDegradeAndCancelRemarkData.rejected.type]: (state, action) => {
      state.degradeAndCancelRemarkData.loading = false;
      state.degradeAndCancelRemarkData.hasErrors = true;
      state.degradeAndCancelRemarkData.message = action.error.message;
    },

    // Send Degrade & Cancellation Remarks CSV
    [sendDegradeCancellationCsvFile.pending.type]: (state) => {
      state.degradeAndCancelRemarkCvData.loading = true;
    },
    [sendDegradeCancellationCsvFile.fulfilled.type]: (state, action) => {
      state.degradeAndCancelRemarkCvData.message = action.payload.message;
      state.degradeAndCancelRemarkCvData.loading = false;
      state.degradeAndCancelRemarkCvData.hasErrors = false;
    },
    [sendDegradeCancellationCsvFile.rejected.type]: (state, action) => {
      state.degradeAndCancelRemarkCvData.loading = false;
      state.degradeAndCancelRemarkCvData.hasErrors = true;
      state.degradeAndCancelRemarkCvData.message = action.error.message;
    },

    // Expert Sessions Report
    [getExpertSessionsReportData.pending.type]: (state) => {
      state.expertSessionsReportData.loading = true;
    },
    [getExpertSessionsReportData.fulfilled.type]: (state, action) => {
      state.expertSessionsReportData.data = action.payload.data;
      state.expertSessionsReportData.message = action.payload.message;
      state.expertSessionsReportData.loading = false;
      state.expertSessionsReportData.hasErrors = false;
    },
    [getExpertSessionsReportData.rejected.type]: (state, action) => {
      state.expertSessionsReportData.loading = false;
      state.expertSessionsReportData.hasErrors = true;
      state.expertSessionsReportData.message = action.error.message;
    },
    // Topic Analysis Report Data
    [getStudentsTopicAnalysisReportData.pending.type]: (state) => {
      state.topicAnalysisReportData.loading = true;
    },
    [getStudentsTopicAnalysisReportData.fulfilled.type]: (state, action) => {
      state.topicAnalysisReportData.data = action.payload.data;
      state.topicAnalysisReportData.message = action.payload.message;
      state.topicAnalysisReportData.loading = false;
      state.topicAnalysisReportData.hasErrors = false;
    },
    [getStudentsTopicAnalysisReportData.rejected.type]: (state, action) => {
      state.topicAnalysisReportData.loading = false;
      state.topicAnalysisReportData.hasErrors = true;
      state.topicAnalysisReportData.message = action.error.message;
    },
    // Project Analysis Report Data
    [getStudentsProjectAnalysisReportData.pending.type]: (state) => {
      state.projectAnalysisReportData.loading = true;
    },
    [getStudentsProjectAnalysisReportData.fulfilled.type]: (state, action) => {
      state.projectAnalysisReportData.data = action.payload.data;
      state.projectAnalysisReportData.message = action.payload.message;
      state.projectAnalysisReportData.loading = false;
      state.projectAnalysisReportData.hasErrors = false;
    },
    [getStudentsProjectAnalysisReportData.rejected.type]: (state, action) => {
      state.projectAnalysisReportData.loading = false;
      state.projectAnalysisReportData.hasErrors = true;
      state.projectAnalysisReportData.message = action.error.message;
    },
    // Lab Wise Student Data Report Chart
    [getLabWiseStudentData.pending.type]: (state) => {
      state.labWiseStudentReportData.loading = true;
    },
    [getLabWiseStudentData.fulfilled.type]: (state, action) => {
      state.labWiseStudentReportData.data.rows = action.payload.data;
      state.labWiseStudentReportData.message = action.payload.message;
      state.labWiseStudentReportData.loading = false;
      state.labWiseStudentReportData.hasErrors = false;
    },
    [getLabWiseStudentData.rejected.type]: (state, action) => {
      state.labWiseStudentReportData.loading = false;
      state.labWiseStudentReportData.hasErrors = true;
      state.labWiseStudentReportData.message = action.error.message;
    },
    //Branch Utilization Chart
    [getLabWiseBranchUtilizationData.pending.type]: (state) => {
      state.branchUtilizationData.loading = true;
    },
    [getLabWiseBranchUtilizationData.fulfilled.type]: (state, action) => {
      state.branchUtilizationData.data.rows = action.payload.data;
      state.branchUtilizationData.message = action.payload.message;
      state.branchUtilizationData.loading = false;
      state.branchUtilizationData.hasErrors = false;
    },
    [getLabWiseBranchUtilizationData.rejected.type]: (state, action) => {
      state.branchUtilizationData.loading = false;
      state.branchUtilizationData.hasErrors = true;
      state.branchUtilizationData.message = action.error.message;
    },
    // getBranchWiseAllLabReport
    [getBranchWiseAllLabReport.pending.type]: (state) => {
      state.getBranchWiseAllLabReport.loading = true;
    },
    [getBranchWiseAllLabReport.fulfilled.type]: (state, action) => {
      state.getBranchWiseAllLabReport.data = action.payload.data;
      state.getBranchWiseAllLabReport.message = action.payload.message;
      state.getBranchWiseAllLabReport.loading = false;
      state.getBranchWiseAllLabReport.hasErrors = false;
    },
    [getBranchWiseAllLabReport.rejected.type]: (state, action) => {
      state.getBranchWiseAllLabReport.loading = false;
      state.getBranchWiseAllLabReport.hasErrors = true;
      state.getBranchWiseAllLabReport.message = action.error.message;
    },

    // getBranchWiseHoldStudentReport
    [branchWiseHoldStudentReport.pending.type]: (state) => {
      state.getBranchWiseHoldStudentReport.loading = true;
    },
    [branchWiseHoldStudentReport.fulfilled.type]: (state, action) => {
      state.getBranchWiseHoldStudentReport.data = action.payload.data;
      state.getBranchWiseHoldStudentReport.message = action.payload.message;
      state.getBranchWiseHoldStudentReport.loading = false;
      state.getBranchWiseHoldStudentReport.hasErrors = false;
    },
    [branchWiseHoldStudentReport.rejected.type]: (state, action) => {
      state.getBranchWiseHoldStudentReport.loading = false;
      state.getBranchWiseHoldStudentReport.hasErrors = true;
      state.getBranchWiseHoldStudentReport.message = action.error.message;
    },

    //CD Grade History
    [searchCDGradeStudentHistory.pending.type]: (state) => {
      state.cdGradeHistoryData.loading = true;
    },
    [searchCDGradeStudentHistory.fulfilled.type]: (state, action) => {
      state.cdGradeHistoryData.data = action.payload.data;
      state.cdGradeHistoryData.message = action.payload.message;
      state.cdGradeHistoryData.loading = false;
      state.cdGradeHistoryData.hasErrors = false;
    },
    [searchCDGradeStudentHistory.rejected.type]: (state, action) => {
      state.cdGradeHistoryData.loading = false;
      state.cdGradeHistoryData.hasErrors = true;
      state.cdGradeHistoryData.message = action.error.message;
    },

    //CD Grade History Csv
    [downloadCsvCDGradeStudentHistory.pending.type]: (state) => {
      state.cdGradeCsvHistoryData.loading = true;
    },
    [downloadCsvCDGradeStudentHistory.fulfilled.type]: (state, action) => {
      state.cdGradeCsvHistoryData.data = action.payload.data;
      state.cdGradeCsvHistoryData.message = action.payload.message;
      state.cdGradeCsvHistoryData.loading = false;
      state.cdGradeCsvHistoryData.hasErrors = false;
    },
    [downloadCsvCDGradeStudentHistory.rejected.type]: (state, action) => {
      state.cdGradeCsvHistoryData.loading = false;
      state.cdGradeCsvHistoryData.hasErrors = true;
      state.cdGradeCsvHistoryData.message = action.error.message;
    },

    //search counsellors information
    [searchCounsellorData.pending.type]: (state) => {
      state.counsellorDetailsData.loading = true;
    },
    [searchCounsellorData.fulfilled.type]: (state, action) => {
      state.counsellorDetailsData.data = action.payload.data;
      state.counsellorDetailsData.message = action.payload.message;
      state.counsellorDetailsData.loading = false;
      state.counsellorDetailsData.hasErrors = false;
    },
    [searchCounsellorData.rejected.type]: (state, action) => {
      state.counsellorDetailsData.loading = false;
      state.counsellorDetailsData.hasErrors = true;
      state.counsellorDetailsData.message = action.error.message;
    },

    //searchStudentExamMarks
    [searchStudentExamMarks.pending.type]: (state) => {
      state.studentExamMarks.loading = true;
    },
    [searchStudentExamMarks.fulfilled.type]: (state, action) => {
      state.studentExamMarks.data = action.payload.data;
      state.studentExamMarks.message = action.payload.message;
      state.studentExamMarks.loading = false;
      state.studentExamMarks.hasErrors = false;
    },
    [searchStudentExamMarks.rejected.type]: (state, action) => {
      state.studentExamMarks.loading = false;
      state.studentExamMarks.hasErrors = true;
      state.studentExamMarks.message = action.error.message;
    },
  },
});
// A selector
export const reportSelector = (state: RootState) => state.report;
export const { clearRemoveMessage } = ReportSlice.actions;
// The reducer
export default ReportSlice.reducer;
